const getUserStorable = (user) => {
  if(!user) {return null}
  let allowed = ['id','username', 'last_name', 'first_name','role', 'email', 'mobile',
                  'image', 'status', 'is_verified', 'gender',  'country_id'];
  return Object.keys(user)
        .filter(key => allowed.includes(key))
        .reduce((obj, key) => { obj[key] = user[key]; return obj;}, {});
}

export default {
  CHANGE_LAYOUT(state, layoutType) {
    state.layout.layoutType = layoutType;
  },
  CHANGE_LAYOUT_WIDTH(state, layoutWidth) {
    state.layout.layoutWidth = layoutWidth;
  },
  CHANGE_LEFT_SIDEBAR_TYPE(state, leftSidebarType) {
    state.layout.leftSidebarType = leftSidebarType;
  },
  CHANGE_LEFT_SIDEBAR_SIZE(state, leftSidebarSize) {
    state.layout.leftSidebarSize = leftSidebarSize;
  },
  CHANGE_TOPBAR(state, topbar) {
    state.layout.topbar = topbar;
  },
  CHANGE_POSITION(state, menuPosition) {
    state.layout.menuPosition = menuPosition;
  },
  CONTENT_PAGE_POSITION(state, position) {
    state.layout.contentPageFixed = position;
  },
  LOGOUT_USER(state) {
    localStorage.removeItem(state.localUserKey);
    state.AppActiveUser = {};
  },
  TOGGLE_LOADER(state, loader) {
    state.layout.loader = loader;
  },
  SET_AUTH_TOKEN(state, data){
    const expirationTime = new Date( new Date().getTime() + +data.expiresIn * 1000);
    const config = {
        token: data.accessToken,
        tokenExpirationDate: expirationTime.toISOString(),
        user: getUserStorable(data.user),
    }
    const encryptedValue = btoa(escape(JSON.stringify(config)));
    localStorage.setItem(state.localUserKey, encryptedValue);
  },
  UPDATE_USER_INFO(state, user) {
    if(!user.id){return};
    state.AppActiveUser = user;
    
    const get_localUser = localStorage.getItem(state.localUserKey);
    const storedData = JSON.parse(unescape(atob(get_localUser)));
    storedData.user = getUserStorable(user),
    localStorage.setItem(state.localUserKey, btoa(escape(JSON.stringify(storedData))));
  },
  SET_COUNTRIES(state, countries){
    state.countries = [...countries];
  },

  /************** CATALOG *****************/
  SET_TASKS(state, tasks){
    state.catalog.tasks = [...tasks];
  },
  SET_CATEGORIES(state, categories){
    state.catalog.categories = [...categories];
  },
}


